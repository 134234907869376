// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()


const images = require.context('../src/images', true)
const imagePath = (name) => images(name, true)


require("bootstrap/dist/js/bootstrap")
require('jquery')



import "../src/javascripts/devise.js";

import "../src/stylesheets/reset.scss";
import "../src/stylesheets/devise.scss";
